<template>
	<div>
		<div class="box">
			<userlogin v-if="$store.state.userlogin == 1"></userlogin>
			<password v-if="$store.state.userlogin != 1"></password>
		</div>

		<a class="copyright" href="https://beian.miit.gov.cn/" target="value">亿嘉欣版权所有2015-2022  蜀ICP备2021013067号</a>
	</div>
</template>

<script>
import userlogin from '../../components/login/login.vue';
import password from '../../components/login/password.vue';
export default {
	components: { userlogin, password },
	name: 'login',
	data() {
		return {
			phone: '',
			password: '',
			code: '',
			logintype: 1,
			codeshow: false,
			codetime: 59,
			case_images:[]
		};
	},
	created() {


	},
	methods: {
		//切换tab
		changelogin(type) {
			this.logintype = type;
		},
		
	}
};
</script>

<style scoped lang="less">
.box {
	// width: 1060px;
	height: 520px;
	position: fixed;
	left: 50%;
	top: 50%;
	// margin-top: -260px;
	// margin-left: -530px;
	transform: translate(-50%,-50%);
	.boxsty {
		vertical-align: top;
		display: inline-block;
		width: 520px;
		height: 520px;
		background-color: #fff;
		margin-right: 20px;
		padding: 56px;
		border-radius: 10px;
		&:last-child {
			margin-right: 0;
		}
	}

	.login {
		.title {
			text-align: center;
			margin-bottom: 30px;
			.text {
				display: inline-block;
				vertical-align: bottom;
				margin-right: 40px;
				font-size: 20px;
				color: #969799;
			}
			.active {
				font-size: 26px;
				color: #323233;
			}
		}
		.inputpad {
			padding-top: 30px;
		}
		.inputbox {
			margin-top: 30px;
			position: relative;
			.label {
				font-size: 16px;
				line-height: 48px;
				border-bottom: 1px solid #dcdee0;
				display: inline-block;
			}
			.input {
				font-size: 16px;
				width: 100%;
				height: 48px;
				line-height: 48px;
				box-sizing: border-box;
				border: none;
				border-bottom: 1px solid #dcdee0;
			}
			.codebox {
				line-height: 48px;
				position: absolute;
				right: 0;
				top: 0;
				font-size: 16px;
				color: #999;
			}
			.inputlabel {
				display: inline-block;
				width: 320px;
				float: right;
			}
			.input:focus {
				border-color: #3773da;
			}
		}
		.loginbtn {
			margin-top: 88px;
			line-height: 48px;
			text-align: center;
			font-size: 14px;
			color: #fff;
			background: #155bd4;
			border-radius: 5px;
			&:hover {
				background: #3773da;
				cursor: pointer;
			}
		}
		.logininfo {
			overflow: hidden;
			margin-top: 24px;
			font-size: 14px;
			line-height: 14px;
			.chekbox {
				float: left;
				span {
					color: #155bd4;
				}
			}
			.changbox {
				float: right;
				font-size: 14px;
				.text {
					color: #155bd4;
					padding-right: 10px;
					line-height: 14px;
					display: inline-block;
					&:last-child {
						position: relative;
						padding-right: 0;
						padding-left: 10px;
					}
					&:last-child::after {
						content: '';
						width: 1px;
						height: 14px;
						position: absolute;
						left: 0;
						top: 0;
						background: #ebedf0;
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	.exp {
		position: relative;
		.title {
			font-size: 26px;
			font-weight: 500;
			color: #323233;
			text-align: center;
		}
		.text {
			line-height: 28px;
			margin: 8px 0 20px;
			font-size: 18px;
			color: #323233;
			text-align: center;
		}
		.link {
			a {
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-pack: center;
				justify-content: center;
				width: 120px;
				margin: 0 auto;
				font-size: 14px;
				color: #155bd4;
			}
		}

		.imgbox {
			width: 408px;
			position: absolute;
			left: 56px;
			top:185px;
			.img {
				text-align: center;
				width: 25%;
				display: inline-block;
				margin-top: 20px;
				img {
					width: 52px;
					height: 52px;
					box-sizing: border-box;
					display: inline-block;
					border-radius: 50%;
					box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.2);
				}
			}
		}
	}
}

.copyright {
	position: fixed;
	left: 0;
	bottom: 40px;
	width: 100%;
	text-align: center;
	color: #999;
	font-size: 14px;
}
</style>
